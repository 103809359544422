var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingSubmit },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-3",
                  attrs: { color: "primary" },
                  on: {
                    click: function () {
                      this$1.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                1
              ),
              _c(
                "v-card",
                { staticClass: "py-4" },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "secondary  ma-4" },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "white--text text-h4" },
                        [_vm._v(" " + _vm._s(this.form.formType) + " Form ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-3" }, [
                    _vm._v(" " + _vm._s(_vm.company.name) + " "),
                  ]),
                  this.company && this.form.formType == "Renewal"
                    ? _c(
                        "v-container",
                        [
                          _c(
                            "div",
                            { staticClass: "mx\n                    -6" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex mt-4" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "deletable-chips": "",
                                      auto: "",
                                      items: _vm.statusOptions,
                                      label: "status",
                                    },
                                    on: { change: _vm.checkStatus },
                                    model: {
                                      value: _vm.form.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "status", $$v)
                                      },
                                      expression: "form.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.isReachable == "Reachable"
                            ? _c("div", {}, [
                                _c(
                                  "div",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        "deletable-chips": "",
                                        items: _vm.renewalStatusOptions,
                                        label: "Renewal status",
                                      },
                                      on: { change: _vm.checkStatusRenewal },
                                      model: {
                                        value: _vm.form.renewalStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "renewalStatus",
                                            $$v
                                          )
                                        },
                                        expression: "form.renewalStatus",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm.isPending
                                      ? _c(
                                          "div",
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                dense: "",
                                                label: "Reason of pending",
                                                outlined: "",
                                                "small-chips": "",
                                                "deletable-chips": "",
                                                multiple: "",
                                                chips: "",
                                                items: _vm.reasonPendingOptions,
                                              },
                                              model: {
                                                value: _vm.form.reasonPending,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "reasonPending",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.reasonPending",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isRejected
                                      ? _c(
                                          "div",
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                dense: "",
                                                label: "Refund",
                                                outlined: "",
                                                items: _vm.refundOptions,
                                              },
                                              model: {
                                                value: _vm.form.refund,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "refund",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.refund",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.refund === "Yes"
                                      ? _c("div", { staticClass: "mb-4" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-start",
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items:
                                                    _vm.optionPaymentMethod,
                                                  dense: "",
                                                  outlined: "",
                                                  label: "Refund Method",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .selectedPaymentMethod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "selectedPaymentMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.selectedPaymentMethod",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.form.selectedPaymentMethod ==
                                          "Bank Transfer"
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-start",
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        items:
                                                          _vm.optionBankType,
                                                        label: "Bank",
                                                        dense: "",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .selectedBankType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "selectedBankType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.selectedBankType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm.form.selectedBankType ==
                                                "Others"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-start",
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label: "Others",
                                                            dense: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .othersSelectedBankType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "othersSelectedBankType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.othersSelectedBankType",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-start",
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        label: " Account No.",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .refundBankAccount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "refundBankAccount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.refundBankAccount",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        label:
                                                          "Account holder name ",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .selectedBankHolder,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "selectedBankHolder",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.selectedBankHolder",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _vm.isRejected
                                      ? _c(
                                          "div",
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                dense: "",
                                                outlined: "",
                                                label: "Reason of rejection",
                                                chips: "",
                                                "deletable-chips": "",
                                                "small-chips": "",
                                                multiple: "",
                                                items: _vm.reasonRejectOptions,
                                              },
                                              model: {
                                                value: _vm.form.reasonReject,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "reasonReject",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.reasonReject",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isPending
                                      ? _c(
                                          "v-container",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "mdi-calendar",
                                                label: "Next follow up date",
                                              },
                                              on: {
                                                "click:prepend":
                                                  _vm.redirectFollowUpDate,
                                                click: _vm.redirectFollowUpDate,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.nextFollowupDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "nextFollowupDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.nextFollowupDate",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isConfirm
                                      ? _c(
                                          "v-container",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "mdi-calendar",
                                                label: "Client payment date",
                                              },
                                              on: {
                                                "click:prepend":
                                                  _vm.redirectConfirmDate,
                                                click: _vm.redirectConfirmDate,
                                              },
                                              model: {
                                                value: _vm.form.confirmAt,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "confirmAt",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.confirmAt",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-dialog",
                                      {
                                        attrs: {
                                          height: "auto",
                                          width: "auto",
                                        },
                                        model: {
                                          value: _vm.isNextRenewalDate,
                                          callback: function ($$v) {
                                            _vm.isNextRenewalDate = $$v
                                          },
                                          expression: "isNextRenewalDate",
                                        },
                                      },
                                      [
                                        _vm.isNextRenewalDate && _vm.isPending
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-center",
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  { staticClass: "pa-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            "Next renewal date : "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {},
                                                          [
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  flat: "",
                                                                  landscape: true,
                                                                  reactive: true,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .nextFollowupDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "nextFollowupDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.nextFollowupDate",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._t(
                                                      "footer",
                                                      function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-end my-3",
                                                              attrs: {
                                                                color: "white",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.resetNextFollowupDate,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Cancel "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.confirmNextFollowupDate,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Confirm "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "v-dialog",
                            {
                              attrs: { width: "auto", height: "auto" },
                              model: {
                                value: _vm.isConfirmDate,
                                callback: function ($$v) {
                                  _vm.isConfirmDate = $$v
                                },
                                expression: "isConfirmDate",
                              },
                            },
                            [
                              _vm.form.renewalStatus == "Confirm" &&
                              _vm.showForm
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c("v-card", { staticClass: "pa-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-column" },
                                          [
                                            _c("p", [
                                              _vm._v("Client payment date : "),
                                            ]),
                                            _c(
                                              "div",
                                              {},
                                              [
                                                _c("v-date-picker", {
                                                  attrs: {
                                                    landscape: true,
                                                    reactive: true,
                                                  },
                                                  model: {
                                                    value: _vm.form.confirmAt,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "confirmAt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.confirmAt",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { attrs: { name: "footer" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-end my-3",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.resetConfirmDate,
                                                    },
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { color: "primary" },
                                                    on: {
                                                      click:
                                                        _vm.confirmConfirmDate,
                                                    },
                                                  },
                                                  [_vm._v(" Confirm ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showForm
                    ? _c(
                        "v-container",
                        [
                          _c("P", [_vm._v(" Remark : ")]),
                          _c("mavon-editor", {
                            staticStyle: { "z-index": "0", height: "auto" },
                            attrs: {
                              id: "renewalRemark",
                              rules: _vm.rules.required,
                              defaultOpen: "edit",
                              toolbarsFlag: false,
                              toolbars: _vm.markdownToolbar,
                              language: "en",
                              placeholder: "Start typing your remark...",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end mt-2 mr-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", plain: "" },
                          on: { click: _vm.clear },
                        },
                        [_vm._v(" Clear ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.api.isLoading,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Confirm ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end mt-2 ma-2" },
                    [_c("AError", { attrs: { api: this.api } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }