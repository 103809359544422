var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function () {
                      this$1.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3 mt-3" },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "secondary white--text text-h4 ma-4",
                      attrs: { flat: "" },
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "text-h4" }, [
                        _vm._v(" Payment Status Update Form "),
                      ]),
                    ],
                    1
                  ),
                  _c("AError", {
                    staticClass: "mt-2",
                    attrs: { api: this.api },
                  }),
                  _c(
                    "v-container",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start mb-5" },
                          [_vm._v(" " + _vm._s(_vm.company.name) + " ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2",
                                attrs: { color: "primary" },
                                on: { click: _vm.redirectToReceipt },
                              },
                              [_vm._v(" View all receipts & invoices ")]
                            ),
                            _c("v-select", {
                              attrs: {
                                label: "Payment status :",
                                items: _vm.paymentStatusOptions,
                              },
                              on: { change: _vm.checkPaymentStatus },
                              model: {
                                value: _vm.paymentInfo.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paymentInfo, "status", $$v)
                                },
                                expression: "paymentInfo.status",
                              },
                            }),
                          ],
                          1
                        ),
                        this.isConfirm
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "my-1" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        label: "Total payment (RM) :",
                                        items: _vm.paymentAmountOptions,
                                      },
                                      on: { change: _vm.checkAmount },
                                      model: {
                                        value: _vm.paymentInfo.total_amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.paymentInfo,
                                            "total_amount",
                                            $$v
                                          )
                                        },
                                        expression: "paymentInfo.total_amount",
                                      },
                                    }),
                                    this.isOtherAmount
                                      ? _c("v-text-field", {
                                          attrs: {
                                            label: "Total payment (RM) :",
                                          },
                                          model: {
                                            value: _vm.paymentInfo.total_amount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.paymentInfo,
                                                "total_amount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "paymentInfo.total_amount",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "my-1" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        label: "Payment method :",
                                        items: _vm.paymentMethodOptions,
                                      },
                                      on: { change: _vm.checkStatus },
                                      model: {
                                        value: _vm.paymentInfo.payment_method,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.paymentInfo,
                                            "payment_method",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "paymentInfo.payment_method",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-container",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "prepend-icon": "mdi-calendar",
                                        label: "Payment date",
                                      },
                                      on: {
                                        "click:prepend":
                                          _vm.redirectPaymentDate,
                                        click: _vm.redirectPaymentDate,
                                      },
                                      model: {
                                        value: _vm.paymentInfo.payment_at,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.paymentInfo,
                                            "payment_at",
                                            $$v
                                          )
                                        },
                                        expression: "paymentInfo.payment_at",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { height: "auto", width: "auto" },
                                    model: {
                                      value: _vm.isPaymentDate,
                                      callback: function ($$v) {
                                        _vm.isPaymentDate = $$v
                                      },
                                      expression: "isPaymentDate",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      { staticClass: "pa-3" },
                                      [
                                        _c("div", { staticClass: "my-1" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "d-flex justify-start mt-3",
                                            },
                                            [_vm._v(" Payment date : ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-start",
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  landscape: true,
                                                  reactive: true,
                                                },
                                                model: {
                                                  value:
                                                    _vm.paymentInfo.payment_at,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.paymentInfo,
                                                      "payment_at",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "paymentInfo.payment_at",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._t("footer", function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-end my-3",
                                                attrs: { color: "white" },
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.resetNextPaymentDate,
                                                    },
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { color: "primary" },
                                                    on: {
                                                      click:
                                                        _vm.confirmNextPaymentDate,
                                                    },
                                                  },
                                                  [_vm._v(" Confirm ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mt-3" },
                          [
                            _c("p", [_vm._v("Remark")]),
                            _c("mavon-editor", {
                              staticStyle: { "z-index": "0", height: "auto" },
                              attrs: {
                                defaultOpen: "edit",
                                toolbarsFlag: false,
                                toolbars: _vm.markdownToolbar,
                                language: "en",
                                placeholder: "Start typing your remark...",
                              },
                              model: {
                                value: _vm.paymentInfo.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paymentInfo, "remark", $$v)
                                },
                                expression: "paymentInfo.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end " },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", plain: "" },
                              on: { click: _vm.clear },
                            },
                            [_vm._v(" Clear ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: this.api.isLoading,
                                color: "primary",
                              },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" Confirm ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }