var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "white--text mb-4",
                      attrs: { color: "secondary" },
                    },
                    [_vm._v(" Request payment update ")]
                  ),
                  _c("AError", { attrs: { api: this.api } }),
                  _c("ASuccessFour", { attrs: { api: this.api } }),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-container",
                        [
                          _c("P", { staticClass: "d-flex justify-start" }, [
                            _vm._v(" Remark : "),
                          ]),
                          _c("mavon-editor", {
                            staticStyle: { "z-index": "0", height: "auto" },
                            attrs: {
                              defaultOpen: "edit",
                              toolbarsFlag: false,
                              language: "en",
                              placeholder: "Start typing your remark...",
                            },
                            model: {
                              value: _vm.remark,
                              callback: function ($$v) {
                                _vm.remark = $$v
                              },
                              expression: "remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: _vm.api.isLoading,
                                color: "primary",
                              },
                              on: { click: _vm.validateInput },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }